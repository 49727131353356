import localforage from 'localforage';
import { isAuthenticated } from 'services/authentication-service';

import { ACTIVE_PASSES, SUBSCRIPTION } from 'state/store';

export const takingThePiss = async () => {
  const isLoggedIn = isAuthenticated();
  const subscription = await localforage.getItem(SUBSCRIPTION);

  if (isLoggedIn && subscription !== null) {
    const { model = {} } = subscription;
    const activePasses = await localforage.getItem(ACTIVE_PASSES);
    const { pissTake = 0 } = model;
    return activePasses > pissTake;
  }
  return false;
};
